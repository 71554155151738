import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import { Permissions } from '@liveline/ll_node_common';
import './style.scss';
import Header from '../../layout/header';
import { Layout } from '../../components/shared';
import Navbar from '../../layout/navbar';
import Footer from '../../layout/footer';
import Login from './login/index';
import logo from '../../assets/dark-theme/icons/ll_full_logo_white_text_xparent_bkgd.svg';
import { SharedObjectProvider } from '../../components/shared/ContextProvider';
import { AbilityContext } from '../../components/shared/Can';

const PrivateRoutes = () => {
    const isAuthenticated = useSelector((x) => Boolean(x?.auth?.user));
    const ability = useSelector((x) => Permissions.defineAbility(x?.auth?.user?.permissions));
    const [darkTheme, setdarkTheme] = useState('darkTheme');
    const [visibleSideMenu, setVisibleSideMenu] = useState(false);
    const [mobileCollapse, setMobileCollpse] = useState(false);
    const [mobile, setMobile] = useState(false);
    const location = useLocation();

    const onCollapseMenu = () => {
        if (!mobile) {
            setVisibleSideMenu(!visibleSideMenu);
        } else {
            setMobileCollpse(!mobileCollapse);
        }
    };

    const resizeFun = () => {
        const width = window.innerWidth;
        const isDefault = localStorage.getItem('isDefault');
        if (width < 1024) {
            if (isDefault !== 'false') {
                setVisibleSideMenu(true);
                setMobile(true);
                setMobileCollpse(true);
            }
        } else {
            setMobileCollpse(false);
            setMobile(false);
            setVisibleSideMenu(false);
        }
    };

    const onToggle = () => {
        localStorage.setItem('isDefault', 'false');
        setMobileCollpse(!mobileCollapse);
    };

    useEffect(() => {
        localStorage.setItem('isDefault', 'true');
        window.addEventListener('resize', resizeFun);
        resizeFun();
        return () => {
            window.removeEventListener('resize', resizeFun);
        };
    }, []);

    useEffect(() => {
        if (location.pathname === '/live_historical_data/monitor_live_data') {
            document.body.classList.add('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [location]);

    return isAuthenticated ? (
        <div className={darkTheme}>
            <AbilityContext.Provider value={ability}>
                <Layout>
                    <Navbar
                        onCollapseMenu={onCollapseMenu}
                        visibleSideMenu={visibleSideMenu}
                        mobile={mobile}
                        mobileCollapse={mobileCollapse}
                    />
                    <Layout className='site-layout'>
                        <SharedObjectProvider>
                            <Header mobile={mobile} onToggle={onToggle} />
                            <Content style={{ height: '100%', padding: 10 }}>
                                <Outlet />
                            </Content>
                            <Footer onToggle={onToggle} mobileCollapse={mobileCollapse} />
                        </SharedObjectProvider>
                    </Layout>
                </Layout>
            </AbilityContext.Provider>
        </div>
    ) : (
        <>
            <div className={darkTheme} style={{ height: '100%' }}>
                <div
                    className='site-layout'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                    }}
                >
                    <div className='logoView'>
                        <img src={logo} alt='logo' className='logoIMG' />
                    </div>
                    <Login />
                </div>
            </div>
        </>
    );
};

export default PrivateRoutes;
