import React from 'react';
import { Input, Form } from 'antd';

const FormInputComponent = ({ name, rules, autoComplete, onKeyPress, allowClear, className, placeholder, disabled, addonAfter, onKeyDown, onChange }) => {
    return (
        <Form.Item name={name} rules={rules} className={className} >
            <Input
                onKeyPress={onKeyPress}
                autoComplete={autoComplete}
                allowClear={allowClear}
                placeholder={placeholder}
                disabled={disabled}
                style={{borderColor: "#424242", }}
                onKeyDown={onKeyDown}
                onChange={onChange}
                addonAfter={addonAfter}
            />
        </Form.Item>
    );
};

export default FormInputComponent;
